import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

export interface IMenuOption {
  label: string;
  active: boolean;
  routeUrl?: string;
  sideMenuicon:string



}
@Component({
  selector: 'rsb-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuOptions: Array<IMenuOption> = [
    {
      label: 'Dashboard',
      active: true,
      routeUrl:'dashboard',
      sideMenuicon:'ri-dashboard-fill'
    },
   
    {
      label: 'My Properties',
      active: false,
      routeUrl:'myProperties',
      sideMenuicon:'ri-building-line'

    
    },
    // {
    //   label: 'My Projects',
    //   active: false,
    //   routeUrl:'myProjects',
    //   sideMenuicon:'ri-file-settings-line'

    
    // },
    // {
      
    //   label: 'Commercial Properties',
    //   active: false,
    //   routeUrl:'',
    //   sideMenuicon:'ri-community-line'

    
    // },
    // {
      
    //   label: 'Add Company',
    //   active: false,
    //   routeUrl:'addCompany/new',
    //   sideMenuicon:'ri-community-line'

    
    // },
    // {
      
    //   label: 'Add Project',
    //   active: false,
    //   routeUrl:'addProject/new',
    //   sideMenuicon:'ri-community-line'

    
    // },
    {
      
      label: 'My Leads',
      active: false,
      routeUrl:'myLead',
      sideMenuicon:'ri-community-line'

    
    },
    {
      
      label: 'My View Leads',
      active: false,
      routeUrl:'myViewLead',
      sideMenuicon:'ri-community-line'

    
    },
    // {
      
    //   label: 'My Companies',
    //   active: false,
    //   routeUrl:'myCompany',
    //   sideMenuicon:'ri-community-line'

    
    // },
    {
      
      label: 'Post Property',
      active: false,
      routeUrl:'post-property/new',
      sideMenuicon:'ri-community-line'

    
    }
    // {
      
    //   label: 'Lead Center',
    //   active: false,
    //   routeUrl:'',
    //   sideMenuicon:'ri-group-3-line'

    
    // },
    // {
    //   label: 'Deals',
    //   active: false,
    //   routeUrl:'',
    //   sideMenuicon:'ri-shake-hands-line'

    
    // },
    // {
    //   label: 'Loans',
    //   active: false,
    //   routeUrl:'',
    //   sideMenuicon:'ri-money-rupee-circle-line'

    
    // },
    // {
    //   label: 'Support',
    //   active: false,
    //   routeUrl:'',
    //   sideMenuicon:'ri-settings-2-line'

    
    // },
  ]
  subMenuOpenFlag: boolean = false;
  currentUrl;
  constructor( 
    private router: Router,
    private route: ActivatedRoute

  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url !== '/') {
          const split = val.url.split('/');
          if (split.length === 2) {
            this.currentUrl = split[1];
            if (this.currentUrl) {
              this.menuOptions.map((obj) => {
                if (obj.routeUrl === this.currentUrl) {
                  obj.active = true;
                } else {
                  obj.active = false;
                }
              })
            }
          }
        }
      }
    })
  }
  openMenuItem(menu: IMenuOption): void {
    if (!menu.active) {
      this.menuOptions.forEach(item => {
        item.active = false;
      });
    }
    this.subMenuOpenFlag = false;
    menu.active = !menu.active;
    // if(menu.routeUrl === 'ecommerce') {
    //   window.open('http://www.indiastudent.com/admin/#/ecommerce', '_blank');
    // }
    // else 
    if (menu.routeUrl && menu.active) {
      this.router.navigate([menu.routeUrl]);
    }
  }
  

}
