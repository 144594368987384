<div class="card padding-card t-align-l no-radius border">
    <div class="card-body">
        <h5 class="card-title mb-4">Project Highlights</h5>
        <div class="row">
            <div class="form-group col-md-12" *ngFor="let swatch of swatchList; let i = index;">
                <label for="location">Enter Amount</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Highlights" id="location"
                        [(ngModel)]="swatchList[i].amount">
                </div>
        
                <label for="location">Enter BHK</label>
                <div class="input-group">
                    
                    <select [(ngModel)]="swatchList[i].bhk">
                    <option value="">Select</option>
                    <option *ngFor="let prop of availableAttributes"
                        value="{{prop}}">{{prop}}</option>
                </select>
                </div>

          
                <label for="Company Person">Company Logo</label>
                <div class="fuzone">
                    <img class="card-img-top" style="height: 150px;" *ngIf="propertyImage" [src]="propertyImage" alt="Card image cap">
                    <div class="fu-text" *ngIf="!propertyImage">
                        <span><i class="mdi mdi-image-area"></i> Click here or drop files to
                            upload</span>
                    </div>
                    <input class="upload" type="file"  #imageInput accept="image/*"
                        (change)="uploadAws($event, imgIndx)">
                </div>
            </div>
        </div>
        <button (click)="addSwatch()">Add New</button>
    </div>
    <button type="button" (click)="back()" class="btn btn-primary btn-lg mb-2 float-right mr-2">Back</button>

    <button type="button" (click)="submit()" class="btn btn-primary btn-lg mb-2 float-right mr-2">Next</button>

</div>