<div class="row">
    <div class="col-xl-8 col-lg-7">
        <div class="all-leads">
            <div class="card shadow mb-4">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h3>All Leads</h3>
                        <div class="right-search">
                          <input type="text" class="form-control d-inline-block" style="width: 200px;" placeholder="Search">
                                <label for="Sort by:">Sort by:</label>
                                <select class="form-control d-inline-block ml-2" style="width: 150px;">
                                    <option>Monthly</option>
                                    <option>Weekly</option>
                                    <option>Daily</option>
                                </select>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-hover">
                        <thead class="">
                            <tr>
                            <th scope="col"><input type="checkbox"></th>
                            <th scope="col">Project Id</th>
                            <th scope="col">Project Name</th>
                            <th scope="col">Date Added</th>                           
                        </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let listings of allListings; let i =index">
                            <td><input type="checkbox"></td>
                            <td>{{listings.listingId}}</td>
                            <td>{{listings.propertyTitle}}</td>
                            <td>{{listings.createdAt}}</td>
                            <td><button class="btn btn-success btn-sm">Complete Form</button></td>
                            </tr>
                        
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-4 col-lg-5">
        <div class="customer-reviews">
            <div class="card shadow mb-4">
                <div class="card-body">
                    <h3>Customer Reviews</h3>
                        <div class="review-card d-flex align-items-start">
                            <img src="assets/img/photo1.png" alt="Hina Kumar">
                            <div class="ml-3">
                                <h3 class="name">Hina Kumar</h3>
                                <div class="stars">
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-fill"></i>
                                <i class="ri-star-line"></i>
                                </div>
                                <p>Quasi quo sit suscipit tempora aperiam rerum placeat id. Voluptatem praesentium excepturi id. Repudiandae incidunt doloremque. Error est et ullam.</p>
                            </div>
                        </div>
                        <div class="review-card d-flex align-items-start">
                            <img src="assets/img/photo2.png" alt="Kishan Reddy">
                            <div class="ml-3">
                                <h3 class="name">Kishan Reddy</h3>
                                <div class="stars">
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-line"></i>
                                </div>
                                <p>Quasi quo sit suscipit tempora aperiam rerum placeat id. Voluptatem praesentium excepturi id. Repudiandae incidunt doloremque. Error est et ullam.</p>
                            </div>
                        </div>
                        <div class="review-card d-flex align-items-start">
                            <img src="assets/img/photo3.png" alt="Rohan Reddy">
                            <div class="ml-3">
                                <h3 class="name">Rohan Reddy</h3>
                                <div class="stars">
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-fill"></i>
                                    <i class="ri-star-line"></i>
                                </div>
                                <p>Quasi quo sit suscipit tempora aperiam rerum placeat id. Voluptatem praesentium excepturi id. Repudiandae incidunt doloremque. Error est et ullam.</p>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        
    </div>
</div>